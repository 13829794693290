@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,700;1,300&display=swap');

:root {
	--color: DodgerBlue;
	--border: grey;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

.App {
	font-family: 'Roboto', sans-serif;
	text-align: center;
	font-size: 20px;
	min-height: 100vh;
	margin: auto;
	scroll-behavior: smooth;
}

.container {
	position: relative;
	width: 100%;
	margin: 0;
	padding: 0;

	.sections-container {
		width: 100%;
		margin: 0;
		padding: 0.1em;
	}
}

section {
	width: 100%;
	margin: 0;
	padding: 0;
}

section.hidden,
img.hidden {
	display: none;
}

h1,
h2,
input,
footer,
.settings,
.photo-container,
#file {
	width: 500px;
	max-width: 100%;
}

.photo-container {
	min-height: 45vh;
	margin: auto;
}

h1 {
	font-size: 24px;
	font-weight: 100;
	margin: auto;
	margin-bottom: 1.2em;
}

h2 {
	font-size: 36px;
	margin: auto;
	margin-bottom: 0.5em;
	margin-top: 0;
	padding: 0.3em;
	color: white;
	background-color: var(--color);
	border: 2px solid var(--color);
}

p {
	font-size: 18px;
}

#file {
	font-size: 20px;
	margin: auto;
	visibility: hidden;
}

.image-btn {
	display: block;
	font-size: 22px;
	width: 500px;
	max-width: 80%;
	margin: auto;
	text-align: center;
	color: white;
	background-color: var(--color);
	border: 2px solid var(--border);
	border-radius: 5px;
	cursor: pointer;
	transition: all 0.5s ease;
	box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);

	&:hover {
		box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.5);
	}
}

#file::-webkit-file-upload-button {
	width: 15ch;
	margin-right: 1em;
	color: white;
	background-color: var(--color);
	border: 2px solid var(--border);
	border-radius: 5px;
	cursor: pointer;
	transition: all 0.5s ease;
}

#file:hover {
	margin-left: 0.5em;
}

img {
	width: 100%;
	max-height: 90vh;
	object-fit: contain;
}

.settings {
	font-size: 16px;
	margin: auto;
	margin-top: 10px;
	padding: 0.7em 1em;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	border: 2px solid grey;
	gap: 0.7em;
}

.slider-container {
	display: flex;
	align-items: center;
	/* border: 2px solid red; */

	.slider {
		width: 300px;
		max-width: 95%;
		margin: 0 0.2em;
		vertical-align: middle;
		cursor: pointer;
	}

	.slider-button {
		font-size: 20px;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 1.2em;
		margin: 0;
		padding: 0;
		color: white;
		background-color: DodgerBlue;
		border: none;
		cursor: pointer;

		&:hover {
			box-shadow: none;
			background-color: gold;
		}
	}
}

button {
	width: 360px;
	max-width: 45%;
	margin: 0;
	font-size: 14px;
	font-weight: 600;
	letter-spacing: 1px;
	padding: 0.3em 0;
	margin-top: 0.6em;
	margin-bottom: 0.8em;
	background-color: transparent;
	border-radius: 5px;
	box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
	cursor: pointer;
	transition: all 0.5s ease;

	&:hover {
		box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.5);
	}
}

button.set {
	color: var(--color);
	border: 2px solid var(--color);
	margin: 0 5px;
	margin-top: 1em;
}

button.reset {
	color: red;
	border: 2px solid red;
	margin: 0 5px;
	margin-top: 1em;
}

.selectors,
.result {
	display: flex;
	flex-wrap: wrap;
	margin: auto;
	width: 100%;
	justify-content: center;
	gap: 1em;
}

.option {
	font-weight: bold;
}

.result p {
	margin: 0;
}

.dataResized {
	border: 2px solid gold;
	width: 100%;
	padding: 0.3em;

	span {
		color: gold;
	}
}

.select {
	color: DodgerBlue;
	font-size: 20px;
	font-weight: bold;
	border: none;
	margin: 0;
	transition-delay: 2s;
}

select {
	font-size: 20px;
	width: 12ch;
	padding-left: 0.5em;
	color: white;
	background-color: var(--color);
	border: 2px solid var(--border);
	border-radius: 5px;
	cursor: pointer;
	transition: all 0.5s ease;
	box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
}

select:hover {
	width: 13ch;
}

a {
	text-decoration: none;
	letter-spacing: 1px;
	padding-top: 0.3em;
	padding-bottom: 0.3em;
	padding-left: 1em;
	padding-right: 1em;
	color: white;
	background-color: var(--color);
	border: 2px solid var(--border);
	border-radius: 5px;
	transition: all 0.5s ease;
	box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);

	&:hover {
		color: gold;
	}
}

.exif-data {
	overflow: hidden;
	white-space: initial;
	width: 100%;
	margin: auto;
	padding: 0.5em;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	border: 2px solid grey;
}

.exif-data p {
	font-size: 12px;
	margin: 0.5em 1em;
}

footer {
	font-weight: 300;
	text-align: center;
	padding: 0.3em 0;
	margin: auto;
	margin-top: 20px;
	color: white;
	background-color: var(--color);
	border: 2px solid var(--color);
}

footer a {
	font-weight: 600;
	border: none;
	color: white;
	padding-bottom: 0.1em;
	transition: all 0.5s ease;
	box-shadow: none;
}

footer a:hover {
	color: var(--color);
	background-color: white;
	font-weight: bold;
}

@media only screen and (min-width: 600px) {
	h1,
	h2,
	input,
	footer,
	.settings,
	.photo-container {
		width: 600px;
		max-width: 100vw;
	}

	.photo-container {
		min-height: 55vh;
	}

	.selectors,
	.result {
		gap: 0.5em;
	}

	select,
	.select {
		font-size: 18px;
	}

	.filters {
		gap: 0.5em;
	}

	.slider-container {
		button,
		.slider {
			width: 560px;
		}
	}
}

@media only screen and (min-width: 768px) {
	h1,
	h2,
	input,
	footer,
	.settings,
	.photo-container {
		width: 760px;
		max-width: 100vw;
	}

	.settings {
		width: 100%;
		gap: 3px;
	}

	.slider-container {
		button,
		.slider {
			width: 680px;
		}
	}

	.exif-data {
		padding: 0.5em;
	}

	.exif-data p {
		font-size: 16px;
		margin: 0 0.8em;
	}
}

@media only screen and (min-width: 1280px) {
	h1,
	h2,
	input,
	footer,
	.photo-container {
		width: 1000px;
		max-width: 100vw;
	}

	.sections-container {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
	}

	section.photo {
		width: 60%;
		padding: 5px;
	}

	section {
		width: 40%;
		padding: 5px;
	}

	select,
	.select {
		font-size: 20px;
	}

	.slider-container {
		button,
		.slider {
			width: 318px;
			max-width: 100%;
		}
	}

	button.set,
	button.reset {
		width: 150px;
	}

	.result {
		gap: 10px;
	}
}
